<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <div class="text-center">
        <v-img
          :lazy-src="$defaultImage"
          :src="getProfileImage() ? getProfileImage() : $defaultImage"
          aspect-ratio="1"
          max-height="140"
          max-width="140"
          class="margin-auto custom-grey-border custom-border-radius-50"
          transition="fade-transition"
        >
        </v-img>
      </div>
      <div class="mt-5">
        <table width="100%">
          <tr>
            <th
              colspan="2"
              class="grey lighten-4 font-size-16 color-custom-blue py-1"
            >
              Basic Information
            </th>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-1" style="width: 120px">
              Asset #
            </td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.barcode">
                <v-chip color="blue darken-4 mr-2" label small outlined>
                  {{ detail.barcode }}
                </v-chip>
                <showBarcode
                  :barcodeUrl="detail.barcode_image"
                  :barcode-text="detail.barcode"
                  title="Own Asset Barcode"
                />
              </template>
              <template v-else> no Asset No. </template>
            </td>
          </tr>
          <tr>
            <td
              valign="top"
              class="font-size-18 font-weight-500 py-1"
              style="width: 150px"
            >
              Name
            </td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.name">
                <span class="text-capitalize">{{ detail.name }}</span>
              </template>
              <template v-else> no Name </template>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-1" style="width: 120px">
              Asset Serial No.
            </td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.serial_no">
                <v-chip color="blue darken-4 mr-2" label small outlined>
                  {{ detail.serial_no }}
                </v-chip>
                <showBarcode
                  :barcodeUrl="detail.serial_image"
                  :barcode-text="detail.serial_no"
                  title=" Asset Serial No. Barcode"
                />
              </template>
              <template v-else> no Asset Serial No. </template>
            </td>
          </tr>
          <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2">Serial No.</td>
              <td class="font-size-18 py-2">
                <template v-if="detail && detail.serial_no">
                  <v-chip outlined label small color="green">
                    {{ detail.serial_no }}
                  </v-chip>
                </template>
                <template v-else> no Serial no. </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2">
                Serial No. Barcode
              </td>
              <td class="font-size-18 py-2">
                <showBarcode
                  title="Serial No. barcode"
                  :barcodeUrl="detail.serial_image"
                />
              </td>
            </tr> -->
          <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1">Batch Number</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.batch_number">
                  <span class="text-capitalize">{{ detail.batch_number }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no batch number </span></template
                >
              </td>
            </tr> -->
          <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1">Selling Price</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.selling_price">
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.selling_price) }}
                  </v-chip>
                </template>
                <template v-else> no Selling Price </template>
              </td>
            </tr> -->
          <tr
            class=""
            v-if="detail && detail.customer && detail.customer.company_name"
          >
            <td
              valign="top"
              class="font-size-18 font-weight-500 py-1"
              style="min-width: 120px !important; width: 50% !important"
            >
              Company Name
            </td>
            <td class="font-size-18 py-1">
              <template
                v-if="detail && detail.customer && detail.customer.company_name"
              >
                <span class="text-capitalize">
                  {{ detail.customer.company_name }}</span
                >
              </template>
              <template v-else>
                <em class="text-muted"> no company name</em>
              </template>
            </td>
          </tr>
          <template
            v-if="
              detail &&
              detail.property &&
              detail.property.property_address &&
              detail.main_type != 'main_company'
            "
          >
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Site Location
              </th>
            </tr>
            <tr>
              <td class="font-size-18 py-1" colspan="2">
                <template
                  v-if="
                    detail &&
                    detail.property &&
                    detail.property.property_address
                  "
                >
                  {{ getFormattedProperty(detail.property) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no site location </em>
                </template>
              </td>
            </tr>
          </template>
          <template
            v-if="detail && detail.main_type == 'main_company' && detail.entity"
          >
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Site Location
              </th>
            </tr>
            <tr>
              <td class="font-size-18 py-1" colspan="2">
                <template v-if="detail && detail.entity">
                  {{ getFormattedCompany() }}
                </template>
                <template v-else>
                  <em class="text-muted"> no site location </em>
                </template>
              </td>
            </tr>
          </template>
        </table>
        <table width="100%" class="mt-4">
          <tr>
            <th
              colspan="2"
              class="font-size-16 color-custom-blue py-1 grey lighten-4"
            >
              Dates Info
            </th>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-1" style="width: 150px">
              Installation Date
            </td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.installation_date">
                {{ formatDate(detail.installation_date) }}
              </template>
              <template v-else>
                <em class="text-muted"> no Installation Date </em>
              </template>
            </td>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-1">Commission Date</td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.activation_date">
                {{ formatDate(detail.activation_date) }}
              </template>
              <template v-else>
                <em class="text-muted"> no Commission Date </em>
              </template>
            </td>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-1">End Life</td>
            <td class="font-size-18 py-1">
              <template v-if="detail && detail.end_life">
                {{ formatDate(detail.end_life) }}
                <span class="red--text font-weight-700" v-if="getDates()">
                  ({{ getDates() }})
                </span>
              </template>
              <template v-else>
                <em class="text-muted"> no End Life </em>
              </template>
            </td>
          </tr>
        </table>
        <table width="100%" class="mt-4" v-if="false">
          <tr>
            <th
              colspan="2"
              class="font-size-16 color-custom-blue py-1 grey lighten-4"
            >
              Location
            </th>
          </tr>
          <tr class="">
            <td class="font-size-18 py-1" colspan="2">
              <template
                v-if="
                  detail &&
                  detail.site_location &&
                  detail.site_location.property_address
                "
              >
                {{ getFormattedProperty(detail.site_location) }}
              </template>
              <template v-else>
                <em class="text-muted"> no address </em>
              </template>
            </td>
          </tr>
        </table>
        <table width="100%" class="mt-4">
          <tr v-if="detail?.asset_tags && detail.asset_tags.length > 0">
            <th
              colspan="2"
              class="font-size-16 color-custom-blue py-1 grey lighten-4"
            >
              Tags
            </th>
          </tr>
          <tr v-if="detail.asset_tags && detail.asset_tags.length > 0">
            <td class="font-size-18 py-1" colspan="2">
              <template
                v-if="detail.asset_tags && detail.asset_tags.length > 0"
              >
                <v-chip
                  v-for="(row, index) in detail.asset_tags"
                  :key="index"
                  class="mr-2 mb-1"
                  text-color="white"
                  :color="row.color"
                  small
                  >{{ row.text }}</v-chip
                >
              </template>
              <em v-else class="text-muted"> no tags</em>
            </td>
          </tr>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
// import Status from "@/view/pages/partials/Status.vue";
// import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import showBarcode from "@/view/components/showBarcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getDates() {
      if (!this.detail?.installation_date || !this.detail?.end_life) {
        return false;
      }

      let date1 = new Date(this.detail?.installation_date);
      let date2 = new Date(this.detail?.end_life);

      if (isNaN(date1) || isNaN(date2)) {
        return "Invalid date format.";
      }

      let years = date2.getFullYear() - date1.getFullYear();
      let months = date2.getMonth() - date1.getMonth();
      let days = date2.getDate() - date1.getDate();

      if (days < 0) {
        months--;
        days += new Date(date2.getFullYear(), date2.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years--;
        months += 12;
      }
      let result = [];
      if (years > 0) result.push(`${years} year${years > 1 ? "s" : ""}`);
      if (months > 0) result.push(`${months} month${months > 1 ? "s" : ""}`);
      if (days > 0) result.push(`${days} day${days > 1 ? "s" : ""}`);

      return result.length > 0 ? `${result.join(", ")}` : "-";
    },
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    totalDays(end_date) {
      var a = moment(end_date, "yyyy-MM-DD");
      var b = moment();
      var final = a.diff(b, "days");
      return final;
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
          tempArr.push(row.city.name);
        }
        if (this.lodash.isEmpty(row.state) === false) {
          tempArr.push(row.state.name);
        }
        if (this.lodash.isEmpty(row.country) === false) {
          tempArr.push(row.country);
        } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    showBarcode,
    // Status,
    // Barcode,
  },
};
</script>
